<template>
  <view-wrapper title="寻找伙伴">
    <div class="all-supplier">
      <div>
        <span class="title">搜索全部</span>
      </div>
      <div class="flex justify-center items-center" style="margin-bottom: 24px">
        <a-input-search
          style="width: 560px"
          placeholder="请输入供应商名称"
          enter-button="搜索"
          v-model:value="keyWord"
          @search.lazy="searchSupplier"
        />
      </div>
      <a-spin tip="Loading..." :spinning="searchSupplierLoading">
        <section class="flex box-list">
          <ul class="item-card item-card_one" v-for="item in searchSupplierData" :key="item.id">
            <li class="flex items-center">
              <span class="title">{{ item.shortName }}</span>
              <span style="margin: 0 12px 0 16px">{{ item.manager?.name }}</span>
              <MessageFilled
                v-if="item.manager.chatUser"
                @click="webim.open(item.manager.chatUser)"
                class="cursor-pointer operation-model"
                style="color: #ffd100"
              />
            </li>
            <li>
              <span>{{
                `${item.area.parent.parent ? item.area.parent.parent.name : item.area.parent.name}${
                  item.area.parent.parent ? item.area.parent.name : item.area.name
                }${item.area.parent.parent ? item.area.name : ''}`
              }}</span>
            </li>
            <li class="flex items-center justify-between">
              <span class="truncate" style="flex: 1">
                品牌经营信息:{{ item.businessType.code === 'W' ? item.vehicleBrands.join('/') : showParts(item) }}</span
              >
              <span style="margin-left: 5px">
                <a-button
                  type="link"
                  class="operation-model"
                  :disabled="collectLoading"
                  @click="addCollect.click(item, true)"
                  >关注</a-button
                >
              </span>
            </li>
          </ul>
        </section>
      </a-spin>
    </div>
    <div class="recommend-supplier">
      <a-spin tip="Loading..." :spinning="recommend.loading">
        <div class="flex items-center">
          <span class="title">今日推荐</span>
          <span style="color: rgba(0, 0, 0, 0.65); margin-left: 16px">{{ date }}</span>
        </div>
        <section class="recommend-supplier-content">
          <ul class="item-card" v-for="(item, index) in recommend.data" :key="index">
            <li class="flex items-center">
              <span class="title">{{ item.shortName }}</span>
              <span style="margin: 0 12px 0 16px">{{ item.manager?.name }}</span>
              <MessageFilled
                v-if="item.manager && item.manager.chatUser"
                @click="webim.open(item.manager.chatUser)"
                class="cursor-pointer operation-model"
                style="color: #ffd100"
              />
            </li>
            <li>
              <span>
                {{
                  `${item.area.parent.parent ? item.area.parent.parent.name : item.area.parent.name}${
                    item.area.parent.parent ? item.area.parent.name : item.area.name
                  }${item.area.parent.parent ? item.area.name : ''}`
                }}</span
              >
            </li>
            <li class="flex items-center justify-between">
              <span class="truncate" style="flex: 1"
                >品牌经营信息:{{
                  item.businessType.code === 'W' ? item.vehicleBrands.join('/') : showParts(item)
                }}</span
              >
              <span style="margin-left: 5px">
                <span v-if="item.isCollected" style="color: #ff4d00">已关注</span>
                <a v-else class="operation-model text-primary" @click="addCollect.click(item)">关注</a>
              </span>
            </li>
          </ul>
        </section>
      </a-spin>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { useAjax, useWebim } from '@vue-mfe/utils'

import { message, Spin as ASpin, InputSearch as AInputSearch } from 'ant-design-vue'
import { MessageFilled } from '@ant-design/icons-vue'

const webim = useWebim()
const toDay = new Date()
const date = `${toDay.getFullYear()}/${toDay.getMonth() + 1}/${toDay.getDate()}`
let keyWord = ref('')

const {
  run: searchSupplier,
  data: searchSupplierData,
  loading: searchSupplierLoading
} = useAjax(
  {
    action: 'GET /client/supplier/collect/search',
    params: () => ({ name: keyWord.value, pageIndex: 1, pageSize: 10 }),
    lazy: true
  }
)
const supplier = ref({})
const { run: collect, loading: collectLoading } = useAjax(
  {
    action: 'POST /client/supplier/collect',
    params: () => ({ supplier: supplier.value }),
    lazy: true
  }
)
const addCollect = reactive(
  {
    loading: false,
    click (value: any, flag?: boolean) {
      supplier.value = value
      collect().then(
        (res) => {
          if (res.status === 200) {
            message.success('关注成功')
            recommend.get()
            if (flag) {
              searchSupplier()
            }
          }
        }
      )
    }
  }
)

const showParts = (supplier: any) => {
  return supplier.parts
    .concat(supplier.customKeywords)
    .map((item: any) => item.message || item.keyword)
    .join('/')
}

const { run: getCollect, data: collectData } = useAjax(
  {
    action: 'GET /client/supplier/collect',
    lazy: true
  }
)

const { run: getRrecommend } = useAjax(
  {
    action: 'GET /client/supplier/collect/recommend',
    lazy: true
  }
)

const recommend = reactive(
  {
    loading: false,
    data: [] as any[],
    get: async () => {
      recommend.loading = true
      await getCollect()
      getRrecommend().then(
        (res: any) => {
          const data = res.data.data
          const collecId = collectData.value.map((item: any) => item.supplier.id)
          data.forEach(
            (item: any) => {
              if (collecId.includes(item.id)) item.isCollected = true
            }
          )
          recommend.data = data
          recommend.loading = false
        }
      )
    }
  }
)

recommend.get()
</script>

<style scoped>
.all-supplier {
  background-color: #fff;
  margin-bottom: 24px;
  padding: 24px;
}
.recommend-supplier {
  padding: 24px;
  background-color: #fff;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.recommend-supplier-content {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.box-list {
  overflow-x: auto;
}
.item-card {
  margin-top: 24px;
  margin-left: 24px;
  width: calc((100% - 48px) / 3);
  min-width: 380px;
  padding: 16px 20px 20px 24px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.item-card .operation-model {
  visibility: hidden;
}
.item-card:hover {
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}
.item-card:hover .operation-model {
  visibility: visible;
}

.item-card:nth-child(3n + 1) {
  margin-left: 0;
}
.item-card li {
  height: 32px;
  line-height: 32px;
}
.item-card.item-card_one {
  margin: 24px;
  margin-right: 0;
  width: 380px;
  flex-shrink: 0;
}
.item-card_one:nth-child(1) {
  margin-left: 0;
}
</style>
